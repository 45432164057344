import styled from '@emotion/styled'

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media screen and (min-width: 740px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    img.cover-image {
      margin: 0;
      max-width: 100%;
    }
  }
`

export default BlogContainer
