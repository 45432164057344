import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Badge, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import BlogContainer from '../components/BlogContainer'
import ProductContainer from '../components/ProductContainer'
import Image from '../components/Image'
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io'

const PostBody = styled.div`
  p {
    margin: 15px 0;
    line-height: 30px;
  }
`

const CoverImage = styled(Image)`
  margin: 0 -20px;
  max-width: calc(100% + 40px);
`

const PostHeader = styled.div`
  margin-bottom: 10px;
`

const PostFooter = styled.div`
  margin: 25px 0;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  a > svg {
    display: inline-block;
  }
`
// Do not show date
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const products = this.props.data.allProductsJson.edges
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          type="article"
          image={post.frontmatter.cover}
        />
        <BlogContainer>
          {post.frontmatter.category.map(cat => (
            <Badge variantColor="yellow" mr={2} py={1} px={2}>
              {cat}
            </Badge>
          ))}
          <PostHeader>
            <Text
              mt={2}
              fontSize="xl"
              fontWeight="semibold"
              lineHeight="short"
              as="h1"
            >
              {post.frontmatter.title}
            </Text>
            {/* <small>{post.frontmatter.date}</small> */}
          </PostHeader>
          <CoverImage
            aspectRatio={1.91}
            src={post.frontmatter.cover}
            sizes={[194, 388, 776, 840]}
            className="cover-image"
            alt={post.frontmatter.title}
          />
          <PostBody>
            <MDXRenderer>{post.body}</MDXRenderer>
            <ProductContainer
              products={products.map(product => product.node)}
            />
          </PostBody>
          <PostFooter>
            <ul>
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    <IoMdArrowBack /> {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} <IoMdArrowForward />
                  </Link>
                )}
              </li>
            </ul>
          </PostFooter>
        </BlogContainer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!, $ids: [String!]) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        cover
        date(formatString: "MMMM DD, YYYY")
        category
      }
      body
    }
    allProductsJson(filter: { id: { in: $ids } }) {
      edges {
        node {
          description
          date
          image
          link
          price
          subtitle
          title
        }
      }
    }
  }
`
